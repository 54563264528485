import React, { useEffect, useState } from 'react';

const FeaturesServices = () => {

  const [featurestext, setFeaturesText] = useState([]);

  const [featureitems, setsetFeatures] = useState([]);

  useEffect(() => {
    fetch(`https://seo-22-4448cec8b9da.herokuapp.com/featureitems`)
      .then((res) => res.json())
      .then((info) => setsetFeatures(info));
  }, []);

  useEffect(() => {
    fetch(`https://seo-22-4448cec8b9da.herokuapp.com/featurestext`)
      .then((res) => res.json())
      .then((info) => setFeaturesText(info));
  }, []);
  return (


    <>

      <section id="section-service">
        {/* Section Spacer */}
        <div className="pb-20 xl:pb-[150px]">
          {/* Section Container */}
          <div className="global-container">
            {/* Section Content Block */}
            <div className="jos mb-10 text-center lg:mb-16 xl:mb-20">
              {
                featurestext.map(a => <div className="mx-auto max-w-md lg:max-w-xl xl:max-w-[950px]">
                  <h4>{a.FeaturesText}</h4>
                  <h1>{a.FeaturesHeading}</h1>
                </div>)
              }


            </div>
            {/* Section Content Block */}
            {/* Service List */}
            <ul className="jos grid grid-cols-1 gap-[2px] overflow-hidden rounded-[10px] border-2 border-black bg-colorViolet  sm:grid-cols-2 lg:grid-cols-4">

              <li className="group bg-white p-[30px] transition-all duration-300 ease-in-out">
                  <div className="relative mb-9 h-[70px] w-[70px]">
                    <img src='https://cdn-icons-png.freepik.com/512/11096/11096747.png' alt="service-icon-black-1" width={70} height={70} /></div>
                  <h3 className="mb-4 block text-xl leading-tight -tracking-[0.5px] group-hover:text-white xl:text-2xl xxl:text-[28px]">
                  SEO Audit Overview</h3>
                  <p className="mb-12 duration-300 group-hover:text-black">
                  A comprehensive analysis of your website’s performance, structure, and current search engine visibility.
                  </p>
                 
                </li>
              <li className="group bg-white p-[30px] transition-all duration-300 ease-in-out ">
                  <div className="relative mb-9 h-[70px] w-[70px]">
                    <img src='https://cdn-icons-png.freepik.com/512/7635/7635284.png' alt="service-icon-black-1" width={70} height={70} /></div>
                  <h3 className="mb-4 block text-xl leading-tight -tracking-[0.5px] group-hover:text-white xl:text-2xl xxl:text-[28px]">
                    Reasons for not Ranking</h3>
                  <p className="mb-12 duration-300 group-hover:text-black">
                  Identifying key factors affecting your website's ranking, including competition, content quality, and technical issues.
                  </p>
                 
                </li>
              <li className="group bg-white p-[30px] transition-all duration-300 ease-in-out ">
                  <div className="relative mb-9 h-[70px] w-[70px]">
                    <img src='https://cdn-icons-png.freepik.com/512/17002/17002086.png' alt="service-icon-black-1" width={70} height={70} /></div>
                  <h3 className="mb-4 block text-xl leading-tight -tracking-[0.5px] group-hover:text-white xl:text-2xl xxl:text-[28px]">
                  Identified Major Issues </h3>
                  <p className="mb-12 duration-300 group-hover:text-black">
                  Highlighting critical problems such as broken links, slow load times, and lack of mobile optimization.
                  </p>
                 
                </li>
              <li className="group bg-white p-[30px] transition-all duration-300 ease-in-out ">
                  <div className="relative mb-9 h-[70px] w-[70px]">
                    <img src='https://cdn-icons-png.freepik.com/512/6459/6459477.png' alt="service-icon-black-1" width={70} height={70} /></div>
                  <h3 className="mb-4 block text-xl leading-tight -tracking-[0.5px] group-hover:text-white xl:text-2xl xxl:text-[28px]">
                  Recommended Solutions & Proven Checklist
                  </h3>
                  <p className="mb-12 duration-300 group-hover:text-black">
                  Actionable steps and strategies tailored to address issues, enhancing your site's SEO effectiveness and performance.
                  </p>
                 
                </li>

            </ul>
            {/* Service List */}
          </div>
          {/* Section Container */}
        </div>
        {/* Section Spacer */}
      </section>



    </>
  );
};

export default FeaturesServices;