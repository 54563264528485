import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';

const BannerSectionSEO = () => {
  const [banner, setBanner] = useState([]);

  useEffect(() => {
    fetch(`https://seo-22-4448cec8b9da.herokuapp.com/banner-seo`)
      .then((res) => res.json())
      .then((info) => setBanner(info));
  }, []);

  const handleBannerSection = (event) => {
    event.preventDefault();
    const bannerHeading = event.target.bannerHeading.value;
    const bannerDetails = event.target.bannerDetails.value;
    const bannerText = event.target.bannerText.value;
    const imageOne = event.target.imageOne.value;
    const buttonText = event.target.buttonText.value;
    const buttonURL = event.target.buttonURL.value;
   

    const bannerSection = {
      bannerHeading,
      bannerDetails,
      bannerText,
      imageOne,
      buttonText,
      buttonURL,
     
    };

    const url = `https://seo-22-4448cec8b9da.herokuapp.com/add-banner`;
    fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(bannerSection),
    })
      .then((res) => res.json())
      .then((result) => {
        alert('Banner is Updated');
      });
  };

  return (
    <>
   <HeaderBottom></HeaderBottom>


      <div>
        <section id="services" className="services-area d-flex align-items-center vh-100  ">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 text-center">
                <h2 className="mb-5 mt-5">Update Banner SEO Audit page</h2>

                {banner.length === 1 && (
                  <>
                    {banner.map((c) => (
                      <Link className="button inline-block rounded-[50px] border-2 border-black bg-[#F6F6EB] text-black after:border-colorOrangyRed after:bg-colorOrangyRed hover:text-white mb-4" to={`/banner-seo/${c._id}`}>
                        Edit Banner Section
                      </Link>
                    ))}
                  </>
                )}
                {banner.length === 0 && (
                  <form className="contact-form text-center" onSubmit={handleBannerSection}>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 mb-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control mb-3"
                            name="bannerHeading"
                            placeholder="Banner Heading One"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 mb-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="bannerText"
                            placeholder="Banner Text"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 mb-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="bannerDetails"
                            placeholder="Banner Details"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 mb-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="imageOne"
                            placeholder="Image One"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 mb-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="buttonText"
                            placeholder="Button Text"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 mb-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="buttonURL"
                            placeholder="Button URL"
                            required
                          />
                        </div>
                      </div>
                      <div className="slider-btn text-center">
                        <button className="button inline-block rounded-[50px] border-2 border-black bg-[#F6F6EB] text-black after:border-colorOrangyRed after:bg-colorOrangyRed hover:text-white" data-animation="fadeInRight" data-delay=".8s">
                          Add Banner
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <HeaderBottom></HeaderBottom>
    </>
  );
};

export default BannerSectionSEO;
