import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";


const AboutSection = () => {

  const [about, setAbout] = useState([]);

  useEffect(() => {
    fetch(`https://seo-22-4448cec8b9da.herokuapp.com/about`)
      .then((res) => res.json())
      .then((info) => setAbout(info));
  }, []);

  return (

    <>

      {
        about.map(a => <section id="about-sec">
          <div className="bg-[#EDEDE0]">
            {/* Section Spacer */}
            <div className="py-20 xl:py-[130px]">
              {/* Section Container */}
              <div className="global-container">
                <div className="grid items-center gap-10 md:grid-cols-[minmax(0,_1fr)_1.3fr] lg:gap-[60px] xl:gap-x-[94px]">
                  <div className="jos" data-jos_animation="fade-left">
                    <div className="overflow-hidden rounded-[10px]">
                      <img src={a.aboutImgOne} alt="content-img-1" width={526} height={550} className="h-auto w-full to-do" />
                    </div>
                  </div>
                  <div className="jos" data-jos_animation="fade-right">
                    {/* Section Content Block */}
                    <div className="mb-6">
                      <h3 className="font-raleway ">
                      {a.aboutHeading}
                      </h3>
                    </div>
                    {/* Section Content Block */}
                    <div className>
                      <p className="mb-8 text-lg leading-[1.33] last:mb-0 lg:text-xl xl:text-2xl">
                      {a.aboutDetails}
                      </p>
                      <p className="mb-8 text-lg leading-[1.33] last:mb-0 lg:text-xl xl:text-2xl">
                        <span className="font-semibold text-[#381FD1]">{a.pointOne} </span>
                        {a.pointOneText}
                      </p>
                      <p className="mb-8 text-lg leading-[1.33] last:mb-0 lg:text-xl xl:text-2xl">
                        <span className="font-semibold text-[#381FD1]">{a.pointTwo} </span>
                        {a.pointTwoText}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Section Container */}
            </div>
            {/* Section Spacer */}
          </div>
        </section>)
      }



    </>
  );
};

export default AboutSection;
