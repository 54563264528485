import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './Banner.css';

const ThankYouBanner = () => {
 


 

  return (
    <>
      <section id="hero-section" className="main-wrapper relative overflow-hidden">
          {/* Section Spacer */}
          <div className="pt-28 lg:pt-40 xl:pt-[195px]">
            {/* Section Container */}
            <div className="global-container">
              {/* Hero Content */}
              <div>
                {/* Section Content Block */}
                <div className="d-flex justify-content-center">
                 <img className="profile-image" src="https://i.postimg.cc/yNBk7Nf7/348447358-617002870147129-3165478737488579979-n.jpg"></img>
                </div>
               <div className="d-flex justify-content-center"> <h5 className="text-center">This is Shah M. Polash, Professional SEO Expert. <br></br>Thank you for submitting your website. My team will manually audit it and send you an email within 24-36 hours. <br></br>If you have any questions, feel free to email me at <span className="text-success">shahmpolash@gmail.com</span>.</h5></div>
              </div>
            </div>
        
          </div>
        </section>
     


    </>
  );
};

export default ThankYouBanner;
