import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';

const EditFeaturesServicesText = () => {
    const [featuresone, setFeaturesText] = useState({});
    const { id } = useParams();

    useEffect(() => {
        fetch(`https://seo-22-4448cec8b9da.herokuapp.com/featurestext/${id}`)
            .then((res) => res.json())
            .then((info) => setFeaturesText(info));
    }, [id]);

    const handleFeaturesTextSection = (event) => {
        event.preventDefault();
        const FeaturesText = event.target.FeaturesText.value;
        const FeaturesHeading = event.target.FeaturesHeading.value;

        const featureSection = {
            FeaturesText,
            FeaturesHeading,
        };

        const url = `https://seo-22-4448cec8b9da.herokuapp.com/featurestext/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(featureSection),
        })
            .then((res) => res.json())
            .then(() => {
                alert('Feature Text Is Updated');
            });
    };

    return (
        <>
            <HeaderBottom />
            <section id="services" className="services-area vh-100">
                <div className="mt-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-12">
                                <h3 className="text-center">Update Features Text</h3>
                                <form className="contact-form" onSubmit={handleFeaturesTextSection}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name">
                                                <input
                                                    className="form-control form-control-lg shadow-lg p-3 mb-2 bg-white rounded"
                                                    name="FeaturesText"
                                                    defaultValue={featuresone.FeaturesText}
                                                    placeholder="Features Text"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name">
                                                <input
                                                    className="form-control form-control-lg shadow-lg p-3 mb-2 bg-white rounded"
                                                    name="FeaturesHeading"
                                                    defaultValue={featuresone.FeaturesHeading}
                                                    placeholder="Features Heading"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 text-center">
                                            <div className="slider-btn mt-3">
                                                <button
                                                    className="button inline-block rounded-[50px] border-2 border-black bg-[#F6F6EB] text-black after:border-colorOrangyRed after:bg-colorOrangyRed hover:text-white"
                                                    data-animation="fadeInRight"
                                                    data-delay=".8s"
                                                >
                                                    Update Features Text
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default EditFeaturesServicesText;
