import React from "react";
import Banner from "../components/Banner";
import ServicesSection from "../components/HomePage/ServicesSection";
import AboutSection from "../components/HomePage/AboutSection";
import TestimonialSection from "../components/HomePage/TestimonialSection";
import PricingSection from "../components/HomePage/PricingSection";
import ContactUs from "./ContactUs";
import WorkSection from "../components/HomePage/WorkSection";
import FAQSection from "../components/HomePage/FAQSection";
import CallToAction from "../components/HomePage/CallToAction";
import FeaturesServices from "../components/HomePage/FeaturesServices";
import OurToDoList from "../components/HomePage/OurToDoList";
import CheckListGIF from "../components/CheckListGIF";



const Home = () => {

  return (
    <div className="page-wrapper relative z-[1] bg-[#F6F6EB]">
      <Banner></Banner>
      <WorkSection></WorkSection>
      <AboutSection></AboutSection>
      <OurToDoList></OurToDoList>
      <ServicesSection></ServicesSection>
      <TestimonialSection></TestimonialSection>
      <ContactUs></ContactUs>
      
    </div>
  );
};

export default Home;
