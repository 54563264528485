import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './Banner.css';

const Banner = () => {
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    fetch(`https://seo-22-4448cec8b9da.herokuapp.com/banner`)
      .then((res) => res.json())
      .then((info) => setBanners(info));
  }, []);



  const handleSubscriber = (event) => {
    event.preventDefault();
    const subscriberEmail = event.target.subscriberEmail.value;

    const sunscribe = {
      subscriberEmail

    };

    const url = `https://seo-22-4448cec8b9da.herokuapp.com/add-subscriber`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(sunscribe),
    })
      .then((res) => res.json())
      .then((result) => {

        alert('Thanks For Subscribe..');
      });
  };



  return (




    <>
      {
        banners.map(b => <section id="hero-section" className="main-wrapper relative overflow-hidden">
          {/* Section Spacer */}
          <div className="pt-28 lg:pt-40 xl:pt-[195px]">
            {/* Section Container */}
            <div className="global-container">
              {/* Hero Content */}
              <div className="grid items-center gap-10 md:grid-cols-2 xl:grid-cols-[minmax(0,_1.3fr)_1fr]">
                {/* Section Content Block */}
                <div>
                  <h4 className="text-[#381FD1]">{b.bannerText}</h4>
                  <h2 className="font-raleway text-5xl md:text-6xl lg:text-7xl xl:text-[100px] xxl:text-[100px]">
                    {b.bannerHeading}</h2>
                </div>
                {/* Section Content Block */}
                <div className="jos flex flex-col gap-10 md:gap-[50px]">
                  <p className="text-lg font-semibold leading-[1.33] md:text-xl lg:text-2xl">
                    {b.bannerDetails}
                  </p>
                  <a href={b.buttonURL} className="button inline-block rounded-[50px] border-2 border-black bg-[#F6F6EB] text-black after:border-colorOrangyRed after:bg-colorOrangyRed hover:text-white">
                    {b.buttonText}</a>
                </div>
              </div>
              {/* Hero Content */}
            </div>
            {/* Section Container */}
            {/* Hero Image */}
            <div className="jos mx-auto mt-12 max-w-[1500px] px-5 md:mt-20" data-jos_animation="zoom">
              <img src={b.imageOne} alt="SEO Audit-Checklist" width={1500} height={700} className="h-auto w-full audit-checklist" />
            </div>
            {/* Hero Image */}
          </div>
          {/* Section Spacer */}
        </section>
        )
      }






    </>
  );
};

export default Banner;
