import React from "react";
import TestimonialSection from "../components/HomePage/TestimonialSection";
import FAQSection from "../components/HomePage/FAQSection";
import SeoScorSection from "../components/HomePage/SeoScorSection";
import Banner from "../components/Banner";
import FeaturesServices from "../components/HomePage/FeaturesServices";
import BannerForSEOAuditPage from "../components/BannerForSEOAuditPage";
import CheckListGIF from "../components/CheckListGIF";

const SEOauditPage = () => {

    return (

        <div className="page-wrapper relative z-[1] bg-[#F6F6EB]"> <main className="main-wrapper relative overflow-hidden">
            <BannerForSEOAuditPage></BannerForSEOAuditPage>
            <SeoScorSection></SeoScorSection>
            <CheckListGIF></CheckListGIF>
            <FeaturesServices></FeaturesServices>
            <TestimonialSection></TestimonialSection>
        </main></div>

    );
};

export default SEOauditPage;
