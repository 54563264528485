import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';

const EditWorkStep = () => {
    const [stepwork, setWork] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        fetch(`https://seo-22-4448cec8b9da.herokuapp.com/worksec/${id}`)
            .then((res) => res.json())
            .then((info) => setWork(info));
    }, [stepwork]);

    const handleBannerSection = (event) => {
        event.preventDefault();
        const stepNumber = event.target.stepNumber.value;
        const stepTitle = event.target.stepTitle.value;
        const stepDetails = event.target.stepDetails.value;

        const workSection = {
            stepNumber,
            stepTitle,
            stepDetails,
        };

        const url = `https://seo-22-4448cec8b9da.herokuapp.com/update-worksec/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(workSection),
        })
            .then((res) => res.json())
            .then((result) => {
                alert('Work Section is Updated');
            });
    };

    return (
        <>
            <HeaderBottom />
            <div>
                <section id="services" className="services-area vh-100">
                <HeaderBottom />
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-12 d-flex flex-column align-items-center">
                                <h2 className="text-center mb-4">Update Working Fetures</h2>
                                <form className="contact-form w-100" onSubmit={handleBannerSection}>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 mb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="stepNumber" defaultValue={stepwork.stepNumber} placeholder="Step icon" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 mb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="stepTitle" defaultValue={stepwork.stepTitle} placeholder="Step Title" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 mb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="stepDetails" defaultValue={stepwork.stepDetails} placeholder="Step Details" required />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <button className="button inline-block rounded-[50px] border-2 border-black bg-[#F6F6EB] text-black after:border-colorOrangyRed after:bg-colorOrangyRed hover:text-white" data-animation="fadeInRight" data-delay=".8s">
                                                Update Work Section
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default EditWorkStep;
