import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import HeaderBottom from '../../components/HomePage/HeaderBottom';

const EditFeaturesServicesItem = () => {

    const [featureitems, setFeatureItems] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        fetch(`https://seo-22-4448cec8b9da.herokuapp.com/featureitems/${id}`)
            .then((res) => res.json())
            .then((info) => setFeatureItems(info));
    }, [id]);

    const handleItemSection = (event) => {
        event.preventDefault();
        const FeaturesImg = event.target.FeaturesImg.value;
        const FeaturesIcon = event.target.FeaturesIcon.value;
        const FeaturesText = event.target.FeaturesText.value;
        const FeaturesDesc = event.target.FeaturesDesc.value;

        const featuresSection = {
            FeaturesImg,
            FeaturesIcon,
            FeaturesText,
            FeaturesDesc,
        };

        const url = `https://seo-22-4448cec8b9da.herokuapp.com/update-featureitems/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(featuresSection),
        })
            .then((res) => res.json())
            .then(() => {
                alert('Feature Items Section is Updated');
            });
    };

    return (
        <>
            <HeaderBottom />

            <div className="container vh-100 d-flex align-items-center justify-content-center">
                <section id="services" className="services-area pt-120 pb-90 fix">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-12 mb-5">
                                <h3 className="text-center"> Update Feature </h3>
                                <form className="contact-form" onSubmit={handleItemSection}>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name">
                                                <input
                                                    className="form-control form-control-lg shadow-lg p-3 mb-3 bg-white rounded"
                                                    name="FeaturesImg"
                                                    defaultValue={featureitems.FeaturesImg}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name">
                                                <input
                                                    className="form-control form-control-lg shadow-lg p-3 mb-3 bg-white rounded"
                                                    name="FeaturesIcon"
                                                    defaultValue={featureitems.FeaturesIcon}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name">
                                                <input
                                                    className="form-control form-control-lg shadow-lg p-3 mb-3 bg-white rounded"
                                                    name="FeaturesText"
                                                    defaultValue={featureitems.FeaturesText}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name">
                                                <input
                                                    className="form-control form-control-lg shadow-lg p-3 mb-3 bg-white rounded"
                                                    name="FeaturesDesc"
                                                    defaultValue={featureitems.FeaturesDesc}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <div className="slider-btn">
                                            <button className="button inline-block rounded-[50px] border-2 border-black bg-[#F6F6EB] text-black after:border-colorOrangyRed after:bg-colorOrangyRed hover:text-white" data-animation="fadeInRight" data-delay=".8s">
                                                Update Feature
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default EditFeaturesServicesItem;
