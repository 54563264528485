import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const EditServicestext = () => {
    const [servicetext, setService] = useState({});
    const { id } = useParams();

    useEffect(() => {
        fetch(`https://seo-22-4448cec8b9da.herokuapp.com/service/${id}`)
            .then((res) => res.json())
            .then((info) => setService(info));
    }, [id]);

    const handleServiceText = (event) => {
        event.preventDefault();
        const servicesubHeading = event.target.servicesubHeading.value;
        const serviceHeading = event.target.serviceHeading.value;

        const serviceSectionone = {
            servicesubHeading,
            serviceHeading,
        };

        const url = `https://seo-22-4448cec8b9da.herokuapp.com/service/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(serviceSectionone),
        })
            .then((res) => res.json())
            .then(() => {
                alert('Service Text is Updated');
            });
    };

    return (
        <>
            <section id="services" className="services-area vh-100 d-flex align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12">
                            <h2 className="text-center mb-4">Update Service Text</h2>
                            {servicetext && (
                                <form
                                    className="contact-form d-flex flex-column align-items-center"
                                    onSubmit={handleServiceText}
                                >
                                    <div className="form-group w-100 mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="servicesubHeading"
                                            defaultValue={servicetext.servicesubHeading || ''}
                                            placeholder="Service Sub Heading"
                                            required
                                        />
                                    </div>
                                    <div className="form-group w-100 mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="serviceHeading"
                                            defaultValue={servicetext.serviceHeading || ''}
                                            placeholder="Service Heading"
                                            required
                                        />
                                    </div>
                                    <div className="slider-btn">
                                        <button
                                            className="button inline-block rounded-[50px] border-2 border-black bg-[#F6F6EB] text-black hover:text-white my-3"
                                            data-animation="fadeInRight"
                                            data-delay=".8s"
                                        >
                                            Update Services Text
                                        </button>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default EditServicestext;
