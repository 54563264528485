import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import HeaderBottom from '../../components/HomePage/HeaderBottom';

const FeaturesServicesAdd = () => {
    const [featurestext, setFeaturesText] = useState([]);
    const [featureitems, setsetFeatures] = useState([]);

    useEffect(() => {
        fetch(`https://seo-22-4448cec8b9da.herokuapp.com/featurestext`)
            .then((res) => res.json())
            .then((info) => setFeaturesText(info));
    }, []);

    useEffect(() => {
        fetch(`https://seo-22-4448cec8b9da.herokuapp.com/featureitems`)
            .then((res) => res.json())
            .then((info) => setsetFeatures(info));
    }, []);

    const handleFeaturesTextSection = (event) => {
        event.preventDefault();
        const FeaturesText = event.target.FeaturesText.value;
        const FeaturesHeading = event.target.FeaturesHeading.value;

        const featuresTextSection = {
            FeaturesText,
            FeaturesHeading,
        };

        const url = `https://seo-22-4448cec8b9da.herokuapp.com/add-feature-text`;
        fetch(url, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(featuresTextSection),
        })
            .then((res) => res.json())
            .then((result) => {
                alert('Features Text is Updated');
            });
    };

    const handleItemSection = (event) => {
        event.preventDefault();
        const FeaturesImg = event.target.FeaturesImg.value;
        const FeaturesIcon = event.target.FeaturesIcon.value;
        const FeaturesText = event.target.FeaturesText.value;
        const FeaturesDesc = event.target.FeaturesDesc.value;

        const featuresSection = {
            FeaturesImg,
            FeaturesIcon,
            FeaturesText,
            FeaturesDesc,
        };

        const url = `https://seo-22-4448cec8b9da.herokuapp.com/add-feature-Item`;
        fetch(url, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(featuresSection),
        })
            .then((res) => res.json())
            .then((result) => {
                alert(' Features Services Section is Updated');
            });
    };

    return (
        <>
            <HeaderBottom />
            <section id="services" className="services-area mb-5">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-lg-8 col-md-12 mx-auto">
                            <h3 className="mt-5">Update Features Services Text</h3>
                            {featurestext.length === 1 && (
                                <>
                                    {featurestext.map(text => (
                                        <Link
                                            className="button inline-block rounded-[50px] border-2 border-black bg-[#F6F6EB] text-black after:border-colorOrangyRed after:bg-colorOrangyRed hover:text-white"
                                            to={`/featurestext-edit/${text._id}`}
                                        >
                                            Update Now
                                        </Link>
                                    ))}
                                </>
                            )}

                            {featurestext.length === 0 && (
                                <form className="contact-form" onSubmit={handleFeaturesTextSection}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name">
                                                <input
                                                    className="form-control form-control-lg shadow-lg p-3 mb-2 bg-white rounded"
                                                    type="text"
                                                    name="FeaturesText"
                                                    placeholder="Features Text"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name">
                                                <input
                                                    className="form-control form-control-lg shadow-lg p-3 mb-2 bg-white rounded"
                                                    type="text"
                                                    name="FeaturesHeading"
                                                    placeholder="Features Heading"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <button
                                                className="button inline-block rounded-[50px] border-2 border-black bg-[#F6F6EB] text-black after:border-colorOrangyRed after:bg-colorOrangyRed hover:text-white"
                                                type="submit"
                                            >
                                                Add Features Text
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            )}

                            <h3 className="mt-5 mb-3">Add Feature Service Item</h3>
                            <form className="contact-form" onSubmit={handleItemSection}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="contact-field p-relative c-name">
                                            <input
                                                className="form-control form-control-lg shadow-lg p-3 mb-2 bg-white rounded"
                                                name="FeaturesImg"
                                                placeholder="Features Image URL"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="contact-field p-relative c-name">
                                            <input
                                                className="form-control form-control-lg shadow-lg p-3 mb-2 bg-white rounded"
                                                name="FeaturesIcon"
                                                placeholder="Features Icon Arrow URL"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="contact-field p-relative c-name">
                                            <input
                                                className="form-control form-control-lg shadow-lg p-3 mb-2 bg-white rounded"
                                                name="FeaturesText"
                                                placeholder="Features Text"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="contact-field p-relative c-name">
                                            <input
                                                className="form-control form-control-lg shadow-lg p-3 mb-2 bg-white rounded"
                                                name="FeaturesDesc"
                                                placeholder="Features Details"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center ">
                                        <button
                                            className=" mt-3 button inline-block rounded-[50px] border-2 border-black bg-[#F6F6EB] text-black after:border-colorOrangyRed after:bg-colorOrangyRed hover:text-white"
                                            type="submit"
                                        >
                                            Add Features Item
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="row text-center">
                        <div className="col-lg-12">
                            <div className="section-title center-align text-center mb-50">
                                <h3 className="mt-5 mb-5">Your Features List for SEO Audit Page</h3>
                            </div>
                        </div>
                    </div>

                    <div className="row gy-4 justify-content-center mb-5">
                        {featureitems.map((s) => (
                            <div className="col-md-6 col-lg-3" key={s._id}>
                                <div className="card feature-card wow fadeInUp h-100 p-4">
                                    <div className="feature-card_img global-img card-img-top">
                                        <img
                                            src={s.FeaturesImg}
                                            className="img-fluid rounded-top"
                                            alt="feature"
                                        />
                                    </div>
                                    <h3 className="feature-card_text mt-3">{s.FeaturesText}</h3>
                                    <div className="card-body">

                                        <Link to={`/edit-feature-items/${s._id}`}>
                                            <h3 className="box-title button inline-block rounded-[50px] border-2 border-black bg-[#F6F6EB] text-black after:border-colorOrangyRed after:bg-colorOrangyRed hover:text-white">
                                                Edit Now
                                            </h3>
                                        </Link>
                                        <p className="feature-card_text mt-3">{s.FeaturesDesc}</p>
                                        <div className="feature-card_icon mb-3">
                                            <img
                                                src={s.FeaturesIcon}
                                                className="img-fluid"
                                                alt="feature icon"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </section>
            <HeaderBottom />
        </>
    );
};

export default FeaturesServicesAdd;
