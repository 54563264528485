import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { signOut } from "firebase/auth";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const NavBar = () => {
  const [user] = useAuthState(auth);
  const [users, setUsers] = useState([]);
  const [logo, setLogo] = useState([]);
  const [profile, setProfile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleSignOut = () => {
    signOut(auth);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const logoResponse = await fetch("https://seo-22-4448cec8b9da.herokuapp.com/logo");
        const logoData = await logoResponse.json();
        setLogo(logoData);

        const usersResponse = await fetch("https://seo-22-4448cec8b9da.herokuapp.com/users");
        const usersData = await usersResponse.json();
        setUsers(usersData);

        const profileResponse = await fetch("https://seo-22-4448cec8b9da.herokuapp.com/profiles/");
        const profileData = await profileResponse.json();
        setProfile(profileData);
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <header className="site-header site-header--absolute is--white py-3" id="sticky-menu">
        <div className="global-container">
          <div className="flex items-center justify-between gap-x-8">
            {/* Header Logo */}
            {logo.map((l) => (
              <a href="/" key={l._id}>
                <img src={l.logo} alt="AIMass" width={180} height={80} />
              </a>
            ))}

            {/* Header Navigation */}
            <div className="menu-block-wrapper">
              <nav className="menu-block" id="append-menu-header">
                <ul className="site-menu-main">
                  <li className="nav-item"><a href="/" className="nav-link-item">Home</a></li>
                  <li className="nav-item"><a href="https://1seoaudit.com/#servicesc" className="nav-link-item">Services</a></li>
                  <li className="nav-item"><a href="https://1seoaudit.com/#contact-us" className="nav-link-item">Contact</a></li>
                </ul>
              </nav>
            </div>

            {/* Destktop menu */}
            {/* Visible on larger devices (small screens and up) */}
            <div className="d-none d-lg-flex align-items-center gap-6 ">
              {user?.email ? (
                <>
                  {profile.map(
                    (pro) =>
                      pro.userEmail === user?.email && (
                        <div className="nav-item dropdown mt-1 me-3" key={pro._id}>
                          <a href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img
                              src={pro.profileImg || "default_profile_image_url"}
                              alt="Profile"
                              className="rounded-circle me-2"
                              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                            />
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end p-3">
                            <li>
                              <Link className="dropdown-item" to="/dashboard">
                                <i className="fa-solid fa-bars text-primary"></i> Dashboard
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/my-order/">
                                <i className="fa-solid fa-cart-shopping text-primary"></i> My Orders
                              </Link>
                            </li>
                            <li><hr className="dropdown-divider" /></li>
                            <li>
                              <i className="fa-solid fa-user text-primary"></i> {pro.userName}
                            </li>
                            <li>{pro.userEmail}</li>
                            <Link to={`/update-profile/${pro._id}`} className="mt-2 mb-2">
                              Edit Profile
                            </Link>
                            <li><hr className="dropdown-divider" /></li>
                            <li>
                              <button className="dropdown-item" onClick={handleSignOut}>
                                <i className="fa-solid fa-right-from-bracket text-primary"></i> Logout
                              </button>
                            </li>
                          </ul>
                        </div>
                      )
                  )}
                </>
              ) : (
                <ul className="list-unstyled">
                  <li className="nav-item d-flex align-items-center">
                    {/* Mobile Login Button */}
                    <Link to="/login" className="button inline-block rounded-[50px] border-2 border-black bg-[#F6F6EB] text-black after:border-colorOrangyRed after:bg-colorOrangyRed hover:text-white mt-3">
                      Log in
                    </Link>
                  </li>
                </ul>
              )}

              {/* Optional Admin Button */}
              {users.map(
                (u, index) =>
                  u.userEmail === user?.email && u.userRole === "Admin" && (
                    <li className="nav-item d-flex align-items-center" key={index}>
                      <Link to="/admin" className="button inline-block rounded-[50px] border-2 border-black bg-[#F6F6EB] text-black after:border-colorOrangyRed after:bg-colorOrangyRed hover:text-white">Admin</Link>
                    </li>
                  )
              )}
              <a
                href="/seo-audit"
                
                rel="noopener noreferrer"
                className="button  rounded-[50px] border-colorViolet bg-colorViolet text-white after:bg-colorOrangyRed hover:border-colorOrangyRed hover:text-white lg:inline-block"
              >
                SEO Audit Now
              </a>
            </div>

            {/* Visible on small devices only */}
            <div className="d-lg-none">
              {/* Here you can add a simplified or different layout for smaller devices */}
            </div>


            {/* Mobile menu */}
            <div className="block lg:hidden">
              <button id="append-menu-header" >
                <div className="" id="mobileMenu">
                  {user?.email ? (
                    <>
                      {profile.map(
                        (pro) =>
                          pro.userEmail === user?.email && (
                            <div
                              className="nav-item dropdown"
                              key={pro._id}
                            >
                              <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img
                                  src={

                                    "https://i.postimg.cc/NjxGmyRC/icons8-hamburger-menu-50.png"
                                  }
                                  alt="Profile"

                                  style={{
                                    width: "40px",
                                    height: "40px",

                                  }}
                                />
                              </a>
                              <ul className="dropdown-menu dropdown-menu-end p-3">
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="/dashboard"
                                  >
                                    <i className="fa-solid fa-bars text-primary"></i>{" "}
                                    Dashboard
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="/my-order/"
                                  >
                                    <i className="fa-solid fa-cart-shopping text-primary"></i>{" "}
                                    My Orders
                                  </Link>
                                </li>
                                <li>
                                  <hr className="dropdown-divider" />
                                </li>
                                <li>
                                  <i className="fa-solid fa-user text-primary"></i>{" "}
                                  {pro.userName}
                                </li>
                                {/* <li>{pro.userEmail}</li> */}
                                {/* <Link
                                  to={`/update-profile/${pro._id}`}
                                  className=""
                                >
                                  Edit Profile
                                  <span className=""></span>
                                </Link> */}
                                <li>
                                  <hr className="dropdown-divider" />
                                </li>
                                <li>
                                  <button
                                    className="dropdown-item"
                                    onClick={handleSignOut}
                                  >
                                    <i className="fa-solid fa-right-from-bracket text-primary"></i>
                                    Logout
                                  </button>
                                </li>
                              </ul>
                            </div>
                          )
                      )}
                    </>
                  ) : (
                    <li className="nav-item" style={{ listStyleType: 'none' }}>
                      <Link to="/login" className="button inline-block rounded-[50px] border-2 border-black bg-[#F6F6EB] text-black after:border-colorOrangyRed after:bg-colorOrangyRed hover:text-white mt-3">
                        Log in
                      </Link>
                    </li>
                  )}
                </div>
              </button>
            </div>


          </div>
        </div>
      </header>
    </>
  );
};

export default NavBar;
