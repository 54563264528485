import React, { useEffect, useState } from 'react';

const WorkSection = () => {

  const [stepwork, setWork] = useState([]);
  const [video, setVideo] = useState([]);

  useEffect(() => {
    fetch(`https://seo-22-4448cec8b9da.herokuapp.com/videos`)
      .then((res) => res.json())
      .then((info) => setVideo(info));
  }, []);

  useEffect(() => {
    fetch(`https://seo-22-4448cec8b9da.herokuapp.com/worksec`)
      .then((res) => res.json())
      .then((info) => setWork(info));
  }, []);


  return (





    <section id="promo-section">
      {/* Section Spacer */}
      <div className="pb-20 pt-20 xl:pb-[130px] xl:pt-[150px]">
        {/* Section Container */}
        <div className="global-container">
          {/* Section Content Block */}
          <div className="jos mx-auto mb-10 text-center md:mb-16 md:max-w-xl lg:mb-20 lg:max-w-3xl xl:max-w-[1000px]">
            <h2 className="font-raleway text-4xl font-medium leading-[1.06] sm:text-[44px] lg:text-[56px] xl:text-[80px]">

            </h2>
          </div>

          <ul className="grid gap-x-6 gap-y-12 md:grid-cols-2 lg:grid-cols-3">

            {
              stepwork.map(w => <li className="jos text-center md:text-left" data-jos_delay="0.1">
                <div className="mx-auto mb-[30px] inline-flex h-10 w-auto justify-center md:justify-normal xxl:h-[60px]">
                  <img src={w.stepNumber} alt="icon-black-promo-1" width={60} height={60} className="h-full w-auto" />
                </div>
                <div className="mb-5 font-raleway text-2xl font-bold leading-[1.33] text-black xl:text-3xl">
                {w.stepTitle}
                </div>
                <p className="text-lg leading-[1.42] xl:text-[21px]">
                 {w.stepDetails}
                </p>
              </li>
              )
            }






          </ul>
          {/* Promo List */}
        </div>
        {/* Section Container */}
      </div>
      {/* Section Spacer */}
    </section>



  );
};

export default WorkSection;