import React, { useEffect, useState } from "react";

const TestimonialSection = () => {
  const [testimonial, setTestimonial] = useState([]);

  const [testimonialtext, setTestimonialText] = useState([]);

  useEffect(() => {
    fetch(`https://seo-22-4448cec8b9da.herokuapp.com/testimonialtext`)
      .then((res) => res.json())
      .then((info) => setTestimonialText(info));
  }, []);

  useEffect(() => {
    fetch(`https://seo-22-4448cec8b9da.herokuapp.com/testimonials`)
      .then((res) => res.json())
      .then((info) => setTestimonial(info));
  }, []);


  const divStyle = {
    backgroundImage: "url(img/testimonial/test-bg-aliments.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "#fff7f5",
  };

  return (



    <> <section className="testimonial-section" id="testimonial-sec">

      <div className="bg-black pb-40 pt-20 xl:pb-[200px] xl:pt-[130px]">

        <div className="global-container">

          <div className="jos mb-10 text-center lg:mb-16 xl:mb-20">
            {
              testimonialtext.map(t => <div className="mx-auto max-w-[300px] lg:max-w-[600px] xl:max-w-[680px]">

                <p className=" text-lg leading-[1.33] last:mb-0 lg:text-xl xl:text-2xl"><span className="font-semibold text-[#381FD1]">{t.testimonialText}</span> </p>
                <h2 className="text-white">{t.testimonialHeading}</h2>
              </div>)
            }

          </div>

          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">


            {
              testimonial.map(a => <div className="jos flex flex-col gap-y-8 rounded-[10px] border-[1px] border-colorCodGray p-[30px] text-white" data-jos_animation="fade-left" data-jos_delay="0.1">
                <div className="block">
                  <img src="assets/img/th-1/rating.svg" alt="rating" width={146} height={25} />
                </div>
                <p>
                  {a.desc}
                </p>
                <div className="flex items-center gap-x-4">
                  <div className="h-[60px] w-[60px] overflow-hidden rounded-full">
                    <img src={a.image} alt="testimonial-img" width={60} height={60} className="h-full w-full object-cover object-center" />
                  </div>
                  <div className="flex flex-col gap-y-1">
                    <span className="block text-lg font-semibold leading-[1.6]">
                      {a.personName}
                    </span>
                    <span className="block text-sm font-light leading-[1.4]">
                      {a.personTitle}
                    </span>
                  </div>
                </div>
              </div>)
            }












          </div>

        </div>

      </div>

    </section>
    </>



  );
};

export default TestimonialSection;
