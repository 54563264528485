import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import gear from "./Images/gear.png";
import orders from './Images/orders.png';
import msg from './Images/message.png';
import sub from './Images/subscriber.png';
import payment from './Images/paypal.png';
import emailicon from './Images/email.png';
import employee from './Images/employee.png';
import video from './Images/video-player.png';
import emailtem from './Images/newsletter.png';
import graphics from './Images/web-design.png';
import videotew from './Images/video-tutorials.png';
import seoreport from './Images/reportSEO.png';

import './AdminMenu.css';

const AdminMenu = () => {
    const [paypal, setPaypal] = useState([]);

    useEffect(() => {
        fetch(`https://seo-22-4448cec8b9da.herokuapp.com/paypal-email`)
            .then((res) => res.json())
            .then((info) => setPaypal(info));
    }, []);

    return (
        <>

            <div className='container'>
                <div className='row justify-content-center mt-5 mb-5'>
                    <div className='single-card d-flex justify-content-center col-lg-3 col-md-5 col-sm-10 icon-box '>
                        <Link to='/setting'>
                            <div className='icon-img'><img src={gear} alt="Setting" /></div>
                            <p>Setting Home</p>
                        </Link>
                    </div>
                    
                    {/* <div className='single-card d-flex justify-content-center col-lg-3 col-md-5 col-sm-10 icon-box '>
                        <Link to='/setting-realestate'>
                            <div className='icon-img'><img src={gear} alt="Setting" /></div>
                            <p>Setting Real Estate</p>
                        </Link>
                    </div> */}
                    <div className='single-card d-flex justify-content-center col-lg-3 col-md-5 col-sm-10'>
                        <Link to='/total-orders'>
                            <div className='icon-img'><img src={orders} alt="Total Orders" /></div>
                            <p>Total Orders</p>
                        </Link>
                    </div>
                    <div className='single-card d-flex justify-content-center col-lg-3 col-md-5 col-sm-10'>
                        <Link to='/admin-support'>
                            <div className='icon-img'><img src="https://i.ibb.co/Mph1Pbj/support.png" alt="Messages" /></div>
                            <p>Support Section</p>
                        </Link>
                    </div>
                    <div className='single-card d-flex justify-content-center col-lg-3 col-md-5 col-sm-10'>
                        <Link to='/all-messages'>
                            <div className='icon-img'><img src={msg} alt="Messages" /></div>
                            <p>Messages</p>
                        </Link>
                    </div>
                    <div className='single-card d-flex justify-content-center col-lg-3 col-md-5 col-sm-10'>
                        <Link to='/all-subscriber'>
                            <div className='icon-img'><img src={sub} alt="Subscribers" /></div>
                            <p>Subscribers</p>
                        </Link>
                    </div>
                    <div className='single-card d-flex justify-content-center col-lg-3 col-md-5 col-sm-10'>
                        <Link to='/all-reports'>
                            <div className='icon-img'><img src={seoreport} alt="Employees" /></div>
                            <p>SEO Reports</p>
                        </Link>
                    </div>
                    <div className='single-card d-flex justify-content-center col-lg-3 col-md-5 col-sm-10'>
                        <Link to='/employees'>
                            <div className='icon-img'><img src={employee} alt="Employees" /></div>
                            <p>Employees</p>
                        </Link>
                    </div>
                    <div className='single-card d-flex justify-content-center col-lg-3 col-md-5 col-sm-10'>
                        <Link to='/video-template'>
                            <div className='icon-img'><img src={video} alt="Employees" /></div>
                            <p>Editable Videos</p>
                        </Link>
                    </div>
                    <div className='single-card d-flex justify-content-center col-lg-3 col-md-5 col-sm-10'>
                        <Link to='/graphic-template'>
                            <div className='icon-img'><img src={graphics} alt="Employees" /></div>
                            <p>Editable Graphics</p>
                        </Link>
                    </div>
                    <div className='single-card d-flex justify-content-center col-lg-3 col-md-5 col-sm-10'>
                        <Link to='/email-template'>
                            <div className='icon-img'><img src={emailtem} alt="Employees" /></div>
                            <p>Email Templates</p>
                        </Link>
                    </div>
                    <div className='single-card d-flex justify-content-center col-lg-3 col-md-5 col-sm-10'>
                        <Link to='/video-tutorial'>
                            <div className='icon-img'><img src={videotew} alt="Employees" /></div>
                            <p>Video Tutorials</p>
                        </Link>
                    </div>

                </div>
            </div>

        </>




    );
};

export default AdminMenu;
