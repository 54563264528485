import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ServicesSection = () => {


  const [items, setItems] = useState([]);
  const [service, setService] = useState([]);

  useEffect(() => {
    fetch(`https://seo-22-4448cec8b9da.herokuapp.com/service-items`)
      .then((res) => res.json())
      .then((info) => setItems(info));
  }, []);


  useEffect(() => {
    fetch(`https://seo-22-4448cec8b9da.herokuapp.com/service`)
      .then((res) => res.json())
      .then((info) => setService(info));
  }, []);


  return (




    <>
      <section id="services" >
        <div className="bg-[#EDEDE0] px-5 sm:px-[50px]">
          <div className="relative z-[1] mx-auto max-w-full rounded-[20px] bg-black">
            {/* Section Spacer */}
            <div className="py-16 sm:px-10 md:px-20 lg:py-20 xl:px-[100px] xl:py-[130px]">
              {/* Section Container */}
              <div className="global-container">
                {/* Section Content Block */}
                {
                  service.map(a => <div className="jos mx-auto mb-10 text-center md:mb-16 md:max-w-xl lg:mb-20 lg:max-w-3xl xl:max-w-[1000px]">
                    <p className=" text-lg leading-[1.33] last:mb-0 lg:text-xl xl:text-2xl"><span className="font-semibold text-[#381FD1]">{a.servicesubHeading}</span> </p>
                    <h2 className="font-raleway text-4xl font-medium leading-[1.06] text-white sm:text-[44px] lg:text-[56px] xl:text-[80px]">
                    {a.serviceHeading}
                    </h2>
                  </div>)
                }

                {/* Section Content Block */}
                {/* Work Process List */}
                <div className="grid grid-flow-dense gap-6 lg:grid-cols-2 xl:grid-cols-3">

                  {
                    items.map(i => <div className="order-1 col-span-1 flex flex-col gap-y-8 rounded-[10px] bg-[#121212] p-[30px] text-white transition-all duration-300 hover:bg-[#381FD1]">
                      <div className="h-10 w-auto xxl:h-[60px]">
                        <img src={i.serviceIcon} alt="working-process-icon" width={62} height={60} className="h-full" />
                      </div>
                      <div className="font-raleway text-2xl font-bold leading-[1.33] lg:text-3xl">
                        {i.serviceTitle}
                      </div>
                      <p className="text-xl leading-[1.33] lg:text-2xl">
                        {i.serviceDetails}
                      </p>
                      
                    </div>
                    )
                  }




                </div>
                {/* Work Process List */}
              </div>
              {/* Section Container */}
            </div>
            {/* Section Spacer */}
            {/* Vertical Line */}
            <div className="absolute left-0 top-0 -z-[1] flex h-full w-full justify-evenly">
              <div className="h-full w-[1px] bg-[#121212]" />
              <div className="h-full w-[1px] bg-[#121212]" />
              <div className="h-full w-[1px] bg-[#121212]" />
              <div className="h-full w-[1px] bg-[#121212]" />
              <div className="h-full w-[1px] bg-[#121212]" />
              <div className="h-full w-[1px] bg-[#121212]" />
              <div className="h-full w-[1px] bg-[#121212]" />
              <div className="h-full w-[1px] bg-[#121212]" />
            </div>
            {/* Vertical Line */}
          </div>
        </div>
      </section>

    </>


  );
};

export default ServicesSection;
