import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../firebase.init';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderBottom from '../components/HomePage/HeaderBottom';

const OrderNow = () => {
    const [user] = useAuthState(auth);
    const navigate = useNavigate();
    const { id } = useParams();
    const [prices, setPrice] = useState([]);
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        fetch(`https://seo-22-4448cec8b9da.herokuapp.com/pricing/${id}`)
            .then((res) => res.json())
            .then((info) => setPrice(info));
    }, [id]);

    useEffect(() => {
        // Example static list of countries; replace or extend as needed
        setCountries([
            'United States', 'Canada', 'Bangladesh', 'United Kingdom', 'Australia', 'India',
            'Germany', 'France', 'Italy', 'Spain', 'Brazil', 'Japan', 'China',
            'South Korea', 'Mexico', 'Argentina', 'South Africa'
        ]);
    }, []);

    const generateUniquePaymentId = () => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let paymentId = "";
        for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            paymentId += characters.charAt(randomIndex);
        }
        return paymentId;
    };

    const handleNewOrder = (event) => {
        event.preventDefault();
        const paymentId = generateUniquePaymentId();
        const packagePrice = event.target.packagePrice.value;
        const packageName = event.target.packageName.value;
        const customerEmail = event.target.customerEmail.value;
        const customerName = event.target.customerName.value;
        const customerAddress = event.target.customerAddress.value;
        const customerCountry = event.target.customerCountry.value;
        const paymentStatus = event.target.paymentStatus.value;
        const orderStatus = event.target.orderStatus.value;
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });

        const order = {
            paymentId,
            packageName,
            packagePrice,
            customerEmail,
            customerName,
            customerAddress,
            customerCountry,
            paymentStatus,
            orderStatus,
            orderDate: formattedDate,
        };

        const url = `https://seo-22-4448cec8b9da.herokuapp.com/new-order`;
        fetch(url, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(order),
        })
        .then((res) => res.json())
        .then(() => {
            navigate('/pending-order');
        });
    };

    return (
        <>
            <HeaderBottom />
            <section id="order" className="vh-100">
            <HeaderBottom />
                <div className="container">
                    <h2 className="text-center mb-4">Order This SEO Plan</h2>
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <form className="bg-light p-4 rounded shadow" onSubmit={handleNewOrder}>
                                <div className="form-group mb-3">
                                    <label htmlFor="packageName">Package Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="packageName"
                                        value={prices.packageName}
                                        placeholder="Package Name"
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="packagePrice">Package Price</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="packagePrice"
                                        value={prices.packagePrice}
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="customerEmail">Email</label>
                                    <input
                                        disabled
                                        type="email"
                                        className="form-control"
                                        name="customerEmail"
                                        value={user?.email}
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="customerName">Full Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="customerName"
                                        placeholder="Full Name"
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="customerAddress">Address</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="customerAddress"
                                        placeholder="Your Address"
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="customerCountry">Country</label>
                                    <select
                                        className="form-control"
                                        name="customerCountry"
                                        required
                                    >
                                        <option value="">Select Your Country</option>
                                        {countries.map((country, index) => (
                                            <option key={index} value={country}>
                                                {country}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <input type="hidden" name="paymentStatus" value="UnPaid" />
                                <input type="hidden" name="orderStatus" value="Pending" />
                                <div className="d-flex justify-content-center">
                                    <button className="button inline-block rounded-[50px] border-2 border-black bg-[#F6F6EB] text-black after:border-colorOrangyRed after:bg-colorOrangyRed hover:text-white" type="submit">Place Order Now</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                
            </section>
            <HeaderBottom />
        </>
    );
};

export default OrderNow;
