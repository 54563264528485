import React from "react";


const CheckListGIF = () => {

return (

        <>
            <section id="hero-section" className="main-wrapper relative overflow-hidden m-5">
                <div>
                    <h2 className="text-center">Our Proven SEO Checklist/To-do List</h2>
                    <div className="jos mx-auto max-w-[1500px]" data-jos_animation="zoom">
                        <img src='https://attherate.dev/1seoaudit/Checklist.gif' alt="SEO Checklist" width={1500} height={700} className="h-auto w-full" />
                    </div>
                </div>
            </section>
        </>
    );
};

export default CheckListGIF;
