// src/components/ErrorPage.js

import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import HeaderBottom from '../HomePage/HeaderBottom';

const ErrorPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <>

    <HeaderBottom></HeaderBottom>
    
    <Container className="text-center vh-100">
    <h1 className="display-3 text-danger">404</h1>
    <h2 className="mb-4">Page Not Found</h2>
    <p>Sorry, the page you are looking for does not exist.</p>
    <Button variant="primary" onClick={handleGoBack}>
      Go Back Home
    </Button>
  </Container></>
  );
};

export default ErrorPage;
