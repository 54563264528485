import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome

const SeoScorSection = () => {
  const navigate = useNavigate();

  const handleReport = (event) => {
    event.preventDefault();
    const CustomerName = event.target.CustomerName.value;
    const websiteURL = event.target.websiteURL.value;
    const Useremail = event.target.Useremail.value;
    const ReportStatus = event.target.ReportStatus.value;

    const addItem = {
      CustomerName,
      websiteURL,
      Useremail,
      ReportStatus,
    };

    const url = `https://seo-22-4448cec8b9da.herokuapp.com/add-report`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(addItem),
    })
      .then((res) => res.json())
      .then((result) => {
    
        navigate('/thanks-for-submit');  // Navigate to /thanks route
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <>
      <main className="main-wrapper relative overflow-hidden" id="seo-audit">
        {/*...::: Breadcrumb Section Start :::... */}

        <section id="section-breadcrumb">
          {/* Section Spacer */}
          <div className="breadcrumb-wrapper">
            <div className="container d-flex justify-content-center align-items-center background-seo p-5">
              <h4 className="text-center text-white fw-normal">
                Submit your website for a manual audit. We will identify why your site isn't ranking on Google, outline the main issues, and provide a comprehensive guide on how to improve your rankings. Additionally, you'll receive our exclusive SEO secret checklist absolutely free!
              </h4>
            </div>
            <div className="container d-flex flex-column align-items-center mb-5">
              <div className="ratings text-center">
                <p className="mb-0 d-flex justify-content-center align-items-center">
                  <span>
                    {/* Rating Icons with Increased Size */}
                    <i className="fas fa-star" style={{ color: 'orange', fontSize: '24px' }}></i>
                    <i className="fas fa-star" style={{ color: 'orange', fontSize: '24px' }}></i>
                    <i className="fas fa-star" style={{ color: 'orange', fontSize: '24px' }}></i>
                    <i className="fas fa-star" style={{ color: 'orange', fontSize: '24px' }}></i>
                    <i className="fas fa-star-half-alt" style={{ color: 'orange', fontSize: '24px' }}></i>
                  </span>
                  <span className="ml-2" style={{ fontSize: '18px' }}>4.7</span>
                </p>
                <h5 className="mb-0 d-flex justify-content-center" style={{ color: 'gray' }}>
                  <span>85+ Reviews</span>
                  <img src="https://img.freepik.com/free-photo/close-up-portrait-serious-attractive-man-student-with-dark-bristle-wears-spectacles_273609-17371.jpg" alt="User 1" className="rounded-circle" style={{ width: '30px', height: '30px', marginRight: '5px' }} />
                  <img src="https://img.freepik.com/free-photo/front-view-smiley-handsome-man-with-crossed-arms_23-2148946218.jpg" alt="User 2" className="rounded-circle" style={{ width: '30px', height: '30px', marginRight: '5px' }} />
                  <img src="https://img.freepik.com/free-photo/handsome-man-with-shiny-skin-posing_23-2149396129.jpg" alt="User 3" className="rounded-circle" style={{ width: '30px', height: '30px', marginRight: '5px' }} />

                </h5>
              </div>
            </div>
            {/* Section Container */}
            <div className="global-container">
              <div className="order-1 bg-white px-[30px] py-[50px] shadow-[0_4px_60px_0_rgba(0,0,0,0.1)] md:order-2">
              <h3 className="text-center">Enter your website URL</h3>
                <div className="d-flex justify-content-center">
                 
                  <p className="m-3">We will provide you with a complete audit report explaining why your website is not ranking and why it isn't getting organic traffic, along with our proven SEO checklist</p>
                  </div>
                <form onSubmit={handleReport} method="post" className="flex flex-col gap-y-5">
                  {/* Form Group */}
                  <div className="grid grid-cols-1 gap-6 xl:grid-cols-2">
                    {/* Form Single Input */}
                    <div className="flex flex-col gap-y-[10px]">
                      <input
                        type="text"
                        name="CustomerName"
                        id="contact-name"
                        placeholder="Your Full Name"
                        className="rounded-[10px] border border-gray-300 bg-white px-6 py-[18px] font-bold text-black outline-none transition-all placeholder:text-slate-500 focus:border-colorOrangyRed"
                        required
                      />
                    </div>
                    {/* Form Single Input */}
                    <div className="flex flex-col gap-y-[10px]">
                      <input
                        type="email"
                        name="Useremail"
                        id="contact-email"
                        placeholder="example@gmail.com"
                        className="rounded-[10px] border border-gray-300 bg-white px-6 py-[18px] font-bold text-black outline-none transition-all placeholder:text-slate-500 focus:border-colorOrangyRed"
                        required
                      />
                    </div>
                  </div>
                  {/* Form Group */}
                  <div className="grid grid-cols-1 gap-6 xl:grid-cols-1">
                    {/* Form Single Input */}
                    <div className="col-lg-12 mb-3">
                      <input
                        type="text"
                        name="websiteURL"
                        id="contact-phone"
                        placeholder="Your Website URL"
                        className="form-control rounded-3 border border-gray-300 bg-white px-3 py-3 fw-bold text-black placeholder-slate-500 focus:border-colorOrangyRed"
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-y-[10px]">
                      <input name="ReportStatus" value='Pending' hidden />
                    </div>
                  </div>
                  {/* Form Group */}
                  <div className='text-center'>
                    <button
                      type="submit"
                      className="button mt-5 rounded-[50px] border-2 border-black bg-black py-4 text-white after:bg-colorOrangyRed hover:border-colorOrangyRed hover:text-white"
                    >
                     Submit Now
                    </button>
                  </div>
                </form>
                {/* Contact Form */}
              </div>
            </div>
            {/* Section Container */}
          </div>
          {/* Section Spacer */}
        </section>

        <section className="contact-section">
        </section>

      </main>
    </>
  );
};

export default SeoScorSection;
