import React, { useEffect, useState } from "react";
import { Link, } from "react-router-dom";

const Footer = () => {



  const [footerSocial, setFooterSocial] = useState([]);
  const [footerCopyright, setFooterCopyright] = useState([]);

  const [logo, setLogo] = useState([]);

  useEffect(() => {
    fetch(`https://seo-22-4448cec8b9da.herokuapp.com/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info));
  }, []);




  const [footerAddress, setFooterAddress] = useState([]);


  useEffect(() => {
    fetch(`https://seo-22-4448cec8b9da.herokuapp.com/footer-address`)
      .then((res) => res.json())
      .then((info) => setFooterAddress(info));
  }, [footerAddress]);

  useEffect(() => {
    fetch(`https://seo-22-4448cec8b9da.herokuapp.com/footer-social`)
      .then((res) => res.json())
      .then((info) => setFooterSocial(info));
  }, []);

  useEffect(() => {
    fetch(`https://seo-22-4448cec8b9da.herokuapp.com/copyrights`)
      .then((res) => res.json())
      .then((info) => setFooterCopyright(info));
  }, [footerCopyright]);



  const handleSubscriber = (event) => {
    event.preventDefault();
    const subemail = event.target.subemail.value;




    const addItem = {
      subemail,



    };

    const url = `https://seo-22-4448cec8b9da.herokuapp.com/add-subscriber`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(addItem),
    })
      .then((res) => res.json())
      .then((result) => {

        alert('Subscribers Request is Sent');
      });


  }




  return (



    <>
      <footer className="relative z-[1] -mt-[70px] overflow-hidden rounded-tl-[30px] rounded-tr-[30px] bg-colorLinenRuffle lg:rounded-tl-[50px] lg:rounded-tr-[50px]">
        {/* Footer Top */}
        <div className="py-[60px] xl:pb-[100px] xl:pt-[130px]">
          <div className="overflow-hidden">
            {/* Footer Text Slider */}

          </div>
        </div>

        <div className="global-container">
          <div className="h-[1px] w-full bg-[#DBD6CF]" />

          <div className="lg grid grid-cols-1 gap-10 py-[60px] md:grid-cols-[1fr_auto_auto] xl:grid-cols-[1fr_auto_auto_1fr] xl:gap-20 xl:py-[100px]">

            {
              footerSocial.map(s => <div className="flex flex-col gap-y-6">
                {
                  logo.map(l => <a href="/" className="inline-block"><img src={l.logo} alt="logo" width={180} height={80} /></a>)
                }

                <p>
                  {s.aboutText}
                </p>

                <ul className="mt-auto flex gap-x-[15px]">
                  <li>
                    <a href={s.fblink} target="_blank" rel="noopener noreferrer" className="group relative flex h-[30px] w-[30px] items-center justify-center rounded-[50%] bg-black hover:bg-colorOrangyRed">
                      <img src="assets/img/th-1/facebook-icon-white.svg" alt="facebook" width={14} height={14} className="opacity-100 group-hover:opacity-0" />
                      <img src="assets/img/th-1/facebook-icon-black.svg" alt="facebook" width={14} height={14} className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100" />
                    </a>
                  </li>

                  <li>
                    <a href={s.inslink} target="_blank" rel="noopener noreferrer" className="group relative flex h-[30px] w-[30px] items-center justify-center rounded-[50%] bg-black hover:bg-colorOrangyRed">
                      <img src="assets/img/th-1/instagram-icon-white.svg" alt="instagram" width={14} height={14} className="opacity-100 group-hover:opacity-0" />
                      <img src="assets/img/th-1/instagram-icon-black.svg" alt="instagram" width={14} height={14} className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100" />
                    </a>
                  </li>
                </ul>

              </div>)

            }



            <div className="flex flex-col gap-y-6">

              <h4 className="text-[21px] font-semibold capitalize text-black">
                Quick Links
              </h4>

              <ul className="flex flex-col gap-y-[10px] capitalize ">
                <li>
                  <a href="/" className="transition-all duration-300 ease-linear text-black hover:text-colorOrangyRed">Home</a>
                </li>
                
                <li>
                  <a href="#services" className="transition-all duration-300 ease-linear text-black hover:text-colorOrangyRed ">Services</a>
                </li>
                <li>
                  <a href="/seo-audit" className="transition-all duration-300 ease-linear text-black hover:text-colorOrangyRed ">SEO Audit</a>
                </li>
                
                <li>
                  <a href="#contact-us" className="transition-all duration-300 ease-linear text-black hover:text-colorOrangyRed">Contact</a>
                </li>
              </ul>
            </div>

            <div className="flex flex-col gap-y-6">


            </div>

            <div className="flex flex-col gap-y-6">
              {/* Footer Title */}
              <h4 className="text-[21px] font-semibold capitalize text-black">
                Subscribe to our newsletter
              </h4>
              {/* Footer Title */}
              {/* Footer Subscriber Form */}
              <form onSubmit={handleSubscriber} action="#" method="post" className>
                <div className="relative h-[50px] max-w-[300px]">
                  <input type="email" name="subemail" id="newsletter-email" placeholder="Enter your email" className="p-y-[18px] h-full w-full rounded-[50px] border-[1px] border-black px-[24px] pr-20 outline-none" required />
                  <button type="submit" className="absolute right-[5px] top-[50%] inline-block h-10 -translate-y-[50%] rounded-[50px] bg-black px-6 transition-all hover:bg-orange-500">
                    <img src="assets/img/th-1/arrow-right-large.svg" alt="newsletter" />
                  </button>
                </div>
              </form>
              {/* Footer Subscriber Form */}
            </div>

          </div>

          <div className="h-[1px] w-full bg-[#DBD6CF]" />

          {
            footerCopyright.map(c => <div className="py-9 text-center">
              <p>{c.copyrightText}</p>
            </div>
            )
          }


        </div>

        <div className="orange-gradient-2 absolute -top-[290px] right-[90px] -z-[1] h-[406px] w-[406px] -rotate-[58deg] rounded-[406px]">
        </div>

        <div className="orange-gradient-1 absolute -right-[200px] -top-[205px] -z-[1] h-[451px] w-[451px] -rotate-[58deg] rounded-[451px]">
        </div>
      </footer>

    </>
  );
};

export default Footer;
