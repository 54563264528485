import React from "react";


const OurToDoList = () => {


  return (

    <>

     <section id="about-sec">
          <div className="bg-[#EDEDE0]">
            {/* Section Spacer */}
            <div className="py-20 xl:py-[130px]">
              {/* Section Container */}
              <div className="global-container">
                <div className="grid items-center gap-10 md:grid-cols-[minmax(0,_1fr)_1.3fr] lg:gap-[60px] xl:gap-x-[94px]">
                  <div className="jos" data-jos_animation="fade-left">
                    <div className="overflow-hidden rounded-[10px]">
                      <img src='https://attherate.dev/1seoaudit/To-Do-List.gif' alt="content-img-1" width={526} height={550} className="h-auto w-full to-do" />
                    </div>
                  </div>
                  <div className="jos" data-jos_animation="fade-right">
                    {/* Section Content Block */}
                    <div className="mb-6">
                      <h3 className="font-raleway ">
                      Our Proven Secret To-Do List/Checklist will be provided after we audit your website
                      </h3>
                    </div>
                    {/* Section Content Block */}
                    <div className>
                      <p className="mb-8 text-lg leading-[1.33] last:mb-0 lg:text-xl xl:text-2xl">
                      First, we will audit your website, then provide our proven checklist. This checklist will outline the necessary steps to improve your Google ranking, increase organic traffic, and resolve all SEO issues effectively.</p>
                      <p className="mb-8 text-lg leading-[1.33] last:mb-0 lg:text-xl xl:text-2xl">
                        <span className="font-semibold text-[#381FD1]">OnPage SEO Guide: </span>
                        In our On-Page SEO Guide, we’ll provide an organized sheet outlining your website’s main issues, keyword placements, optimization tips for titles, and recommendations for image compression and more.
                      </p>
                      <p className="mb-8 text-lg leading-[1.33] last:mb-0 lg:text-xl xl:text-2xl">
                        <span className="font-semibold text-[#381FD1]">OffPage SEO Guide: </span>
                        In our Off-Page SEO Guide, our checklist will outline where to obtain backlinks, the number of quality backlinks needed, and how to secure relevant backlinks to enhance your website's authority.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Section Container */}
            </div>
            {/* Section Spacer */}
          </div>
        </section>

    </>
  );
};

export default OurToDoList;
