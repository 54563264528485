import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './Banner.css';

const BannerForSEOAuditPage = () => {
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    fetch(`https://seo-22-4448cec8b9da.herokuapp.com/banner-seo`)
      .then((res) => res.json())
      .then((info) => setBanners(info));
  }, []);



  const handleSubscriber = (event) => {
    event.preventDefault();
    const subscriberEmail = event.target.subscriberEmail.value;

    const sunscribe = {
      subscriberEmail

    };

    const url = `https://seo-22-4448cec8b9da.herokuapp.com/add-subscriber`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(sunscribe),
    })
      .then((res) => res.json())
      .then((result) => {

        alert('Thanks For Subscribe..');
      });
  };

  return (
    <>
      {
        banners.map(b => <section id="hero-section" className="main-wrapper relative overflow-hidden">
          {/* Section Spacer */}
          <div className="pt-28 lg:pt-40 xl:pt-[195px]">
            {/* Section Container */}
            <div className="global-container">
              {/* Hero Content */}
              <div className="grid items-center gap-10 md:grid-cols-2 xl:grid-cols-[minmax(0,_1.3fr)_1fr]">
                {/* Section Content Block */}
                <div>
                  <h4 className="">
                  Business growth depends on organic traffic.</h4>
                  <h2 className="font-raleway text-5xl md:text-6xl lg:text-7xl xl:text-[100px] xxl:text-[100px]">
                  Organic traffic fuels growth</h2>
                </div>
                {/* Section Content Block */}
                <div className="jos flex flex-col gap-10 md:gap-[50px]">
                  <p className="text-lg font-semibold leading-[1.33] md:text-xl lg:text-2xl">
                  You must identify the reasons your website isn't ranking to improve its visibility and attract the organic traffic it needs.
                  </p>
                  <a href='/seo-audit#seo-audit' className="button inline-block rounded-[50px] border-2 border-black bg-[#F6F6EB] text-black after:border-colorOrangyRed after:bg-colorOrangyRed hover:text-white">
                  Submit your website for audit - it's free</a>
                </div>
              </div>
              {/* Hero Content */}
            </div>
            {/* Section Container */}
            {/* Hero Image */}
           
            {/* Hero Image */}
          </div>
          {/* Section Spacer */}
        </section>
        )
      }


    </>
  );
};

export default BannerForSEOAuditPage;
